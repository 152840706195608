.amplenote-embed {
  height: 400px;
  width: 100%;
}

.PageContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

#amplenote-container {
  height: 400px;
}

#ample-desc {
  text-align: center;
}

#avatar-image {
  border-radius: 75%;
  padding-right: 20px;
  vertical-align: top;
  width: 100px;
}

#greeting {
  padding-top: 20px;
  text-align: center;
  vertical-align: center;
}

#contact-info {
  font-size: medium;
  text-align: center;
}

#contact-page-heading {
  padding-bottom: 5px;
  padding-top: 30px;
  text-align: center;
}

#nav-bar {
  padding-top: 10px;
}

a:link {
  color: rgb(112, 150, 184);
  text-align: center;
  text-decoration: none;
}

a:visited {
  color: rgb(146, 122, 173);
  text-align: center;
  text-decoration: none;
}

body {
  color: aliceblue;
}

b {
  font-size: larger;
  font-variant: small-caps;
  font-weight: lighter;
}

div.navLink {
  color: rgb(112, 150, 184);
  display: inline;
  font-size: small;
  padding: 5px;
}

div.resumeLink {
  color: rgb(112, 150, 184);
  display: inline;
  padding: 5px;
}

h1 {
  font-size: medium;
  text-align: center;
}

h2 {
  font-size: large;
  padding-top: 18px;
  text-align: center;
  padding: 5px;
}

html {
  align-items: center;
  background-color: #282c34;
  color: grey;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}

table {
  font-size: medium;
  line-height: 150%;
  margin-left: auto;
  margin-right: auto;
}

tbody {
  text-align: center;
}

td {
  align-self: auto;
  padding: 10px;
  text-align: justify;
}

th {
  padding: 10px;
  text-align: justify;
}

tr {
  padding: 10px;
}
